import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
export class LeaveType {
  leaveTypeId: any;
  leaveName: string;
  noOfDays: any;
  createdOn: any;
  status: any;
}

@Component({
  selector: 'app-leave-type-list',
  templateUrl: './leave-type-list.component.html',
  styleUrls: ['./leave-type-list.component.css']
})
export class LeaveTypeListComponent implements OnInit {

  isLoading: boolean;
  leaveTypeForm: FormGroup;
  endDate_disable: boolean;
  matcher = new MyErrorStateMatcher();
  myFilter: any;
  noOFDays: any = 0;
  min: any;
  WorkingDays: any;
  empId: any;
  type: LeaveType[] = [];
  employeeList: any[] = [];
  leaveTypeId: any;
  employeeNumber:any;

  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = false;

    this.endDate_disable = true;

    this.gs.getLeaveType().subscribe(
      (d: any) => {
        d.map(
          e=>{
            if(e.status== 1){
              this.type.push(e)
            }
          }
        )
      }
    )
    // OverAll Employee Active and Inactive List in Dropdown
    // this.gs.getEmpList().subscribe(
    //   (e: any[]) => {
    //     console.log("Employee List");
    //     e.forEach(element => {
    //       if(element.status==1)
    //       {
    //         this.employeeList.push(element);
    //       }
    //     });
    //     console.log("Employee List"+this.employeeList);
    //   }
    // )

    // OverAll Employee Active List in Dropdown
    this.gs.getEmpList1().subscribe(
      (e: any[]) => {
        this.employeeList=e;
        console.log("Employee List"+this.employeeList);
      }
    )

   
    let x = JSON.parse(localStorage.getItem('data'));
    this.empId = x.employeeId;

    this.leaveTypeForm = this.fb.group({
      status: [''],
      employee: ['', Validators.required],
      employeeNumber: [''],
      leaveType: ['', Validators.required]
    })
    this.gs.getEmpListById(this.empId).subscribe(
      (d: any) => {
        //working days
        this.WorkingDays = d.noOfWorkingDays;
        // get date
        const sample_date_data: any[] = [];
        this.gs.get_leave_date(this.empId).subscribe((a: any) => {
          a.forEach(element => {
            let x = element.split("-");
            sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
          });
          let x = sample_date_data;
        })
        if (this.WorkingDays == 5) {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime();
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && day !== 6 && !sample_date_data.find(x => x == time);
          }
        } else {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime()
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && !sample_date_data.find(x => x == time);
          }
        }
      }
    );
  }

  get f() {
    return this.leaveTypeForm.controls;
  }

  nofoDays(i) {
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value)
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * this.WorkingDays + 1;

    // If not even number of weeks, calc remaining weekend days
    if (totalDays % 7) {
      s.setDate(s.getDate() + wholeWeeks * 7);
      if (this.WorkingDays == 5) {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 6) {
            ++days;
          }
        }
      }
      else {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 7) {
            ++days;
          }
        }
      }
    }
    this.noOFDays = days;
    return days;
  }
  // date validation
  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false
  }

  empValue(e){
   this.employeeNumber = e;
  }

  onSubmit(data) {
    if (this.leaveTypeForm.invalid) {
      return
    }
    let x = this.leaveTypeForm.value;
    x['employee'] = {
      employeeId: x.employee
    }

    x['leaveType'] = {
      leaveTypeId: x.leaveType
    }

    x['status'] = 1

   
    
    this.isLoading = true;
    this.gs.leaveBalance(x).subscribe(
      (d: any) => {
        Swal.fire({
          text: d.description,
          icon: 'success'
        })
        this.isLoading = false;
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.router.navigate(['/Home']);
      }
    )
  }

}
