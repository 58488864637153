import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { join } from 'path';
import { MatDialog } from '@angular/material/dialog';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { DenyreasonComponent } from '../denyreason/denyreason.component';

@Component({
  selector: 'app-wfh-emp-request',
  templateUrl: './wfh-emp-request.component.html',
  styleUrls: ['./wfh-emp-request.component.css']
})
export class WfhEmpRequestComponent  implements OnInit {
  isLoading: Boolean;
  list_count = 0;
  noData=false;
  data: any[] = [];
  query
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'organizationEmail', 'userType', 'startDate', 'endDate', 'noOfDays','reason','workFromHomeStatus', 'star',];
  dataSource: any;
  loader=true;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"));
    let mail = x.organizationEmail;
    this.gs.getWFHRequest(mail, 3).subscribe((d: any) => {
      this.loader=false;
      console.log(d);
      if(d.statusCode==204)
      {
        this.noData=true;
        console.log("NoData");
      }
      console.log(d)
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.userType.userType
        +data.startDate + data.endDate + data.noOfDays + data.workFromHomeStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length;
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log(" Wfh emp request error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  edit(i) {
    console.log(i);
    this.router.navigate(['Home/type-edit', i.leaveTypeId]);

  }
  delete(i) {
    console.log(i)
    Swal.fire({
      title: 'Are you sure? Do you want to Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {

        this.gs.deleteLeaveType(i.leaveTypeId).subscribe(
          d => {
            Swal.fire(
              'done!',
              'Your file has been deleted.',
              'success'
            )
            this.gs.getLeaveType().subscribe((d: any) => {
              console.log(d)
              this.data = d
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              if (this.list_count < 1) {
                window.location.reload()
              }
            })
          },
          e => {
            Swal.fire(
              'Error!',
              'Somthing went wrong',
              'error'
            )
          },
          () => console.log('done')
        )
      }
      //
    })
  }
  Accept(i) {
    console.log(i)
    if (i.applyTo == 0) {
      i['appplyTo'] = [];
    }
    else {
      i['applyTo'] = [i.employee.organizationEmail];
    }
    i['cc'] = [];
    Swal.fire({
      title: 'Are you sure? Do you want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Approve!'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true
        i['workFromHomeStatus'] = 5
        this.gs.Actionwfh(i.workFromHomeId, i).subscribe(d => {
          this.isLoading = false
          Swal.fire(
            "Done",
            'Approved!',
          )
          window.location.reload()
        },
          err => {
            this.isLoading = false
            Swal.fire(
              'error!',
              err.error.message
            )
          }
        );
      }
      //
    })
  }
  denied(i) {

    if (i.applyTo == 0) {
      i['appplyTo'] = [];
    }
    else {
      i['applyTo'] = [i.employee.organizationEmail];
    }
      i['cc'] = [];

    // leaveStatus
    Swal.fire({
      title: 'Are you sure? Do you want to Deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Deny'
    }).then((result) => {
      if (result.value) {

        i['workFromHomeStatus'] = 6
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "" ,
            width:'500px',
            disableClose:true,
          })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              this.isLoading = true
              i['workfromhomedenyReason'] = result.denyReason;

              this.gs.Actionwfh(i.workFromHomeId, i).subscribe(d => {
                this.isLoading = false;
                Swal.fire({
                  text:'Denied',
                  icon:'success'
                })
                window.location.reload()
              },
                err => {
                  this.isLoading = false;
                  Swal.fire({
                     text:err.error.message,
                     icon:'error'
                  })
                }
              );
            }
          });
        }
      }
    })
  }
  Reason(i) {
    console.log(i);
    this.dialog.open(Reason, {
      data: i.reason
    });
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }   
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }
}


