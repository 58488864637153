import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-permissionsettings',
  templateUrl: './permissionsettings.component.html',
  styleUrls: ['./permissionsettings.component.css']
})
export class PermissionsettingsComponent implements OnInit {
  timesettingsForm:FormGroup;
  isSubmitted: boolean = false;
  settingsdata:any = {};
  isLoading:any = false;

  constructor(private fb: FormBuilder,
    private GlobalserviceService : GlobalserviceService,
    private datepipe : DatePipe,
    private router:Router,
    private dialogRef : MatDialogRef<PermissionsettingsComponent> ,  @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  ngOnInit(): void {
    this.timesettingsForm = this.fb.group({
      maxtime:['',Validators.required]
    })

    this.GlobalserviceService.
    getsettingsbyId(this.data)
    .subscribe
    ((data:any)=>
    {
      this.settingsdata = data;
      this.timesettingsForm.patchValue({
        maxtime:this.settingsdata.maximumPermissionHours,
      })
    }
    )
  }
  save(){
    console.log("Submit");
    this.isSubmitted=true;
    if(this.timesettingsForm.invalid){
      return
    }
    this.isLoading = true;

      var settingsobj = {
      
          "settingsId":  this.settingsdata.settingsId,
          "maximumPermissionHours": this.timesettingsForm.value.maxtime,
          "status":  this.settingsdata.status,
          "minimumWokrHours": this.settingsdata.minimumWokrHours,
          "maximumWokrHours": this.settingsdata.maximumWokrHours,
      }
       console.log(settingsobj);

       this.GlobalserviceService.
       updatesettings(settingsobj)
       .subscribe
       ((d:any)=>
       {
        Swal.fire({
          text: d.description,
          icon: 'success'
        })
         this.dialogRef.close(d);
        setTimeout(() => {
          this.router.navigate(['./Emptimesettings-list'])
         }, 3000);
        this.isLoading = false;
       },
       err => {
         Swal.fire({
           text: err.error.description,
           icon: 'error'
         })
         this.isLoading = false;
       }
       )
  }
}
