<form [formGroup]="designationForm" (ngSubmit)="save()">
    <div class="d-flex mb-3">
        <div class="title-block">
            <!-- <mat-card class="header">
                <mat-icon class="material-icons-outlined logo">work_outline</mat-icon> -->
                <div class="title">{{data ? 'Update':'Create'}} Designation</div>
            <!-- </mat-card> -->
            <!-- <h2 class="title">Create Employee Timings</h2> -->
        </div>
        <div class="concel-block">
            <mat-icon class="material-icons cancel-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field class="example-full-width" appearance="outline" style="display:inline">
                <mat-label>Designation<span class="text-danger">*</span></mat-label> 
                <input matInput formControlName="designation" placeholder="Enter Designation">
            </mat-form-field>
            <div *ngIf="(isSubmitted && designationForm.controls['designation'].invalid) 
                    || designationForm.controls['designation'].dirty 
                    || designationForm.controls['designation'].touched">
                <small *ngIf="designationForm.controls['designation'].errors?.required" id="req" 
                style="color:red;font-size:12px;position: relative;bottom: 20px">Designation required</small>
                <small *ngIf="designationForm.controls['designation'].errors?.pattern" id="req"
                style="color:red;font-size:12px;position: relative;bottom: 20px">Please enter valid designation name</small> 
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field class="example-full-width" appearance="outline" style="display:inline">
                <mat-label>Description</mat-label> 
                <textarea matInput type="text" formControlName="description" placeholder="Enter Description"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row float-right">
        <div class="col-md-12 pt-1">
            <button mat-stroked-button color="primary" class="form-button apply-button" type="submit">{{data ? 'Update':'Create'}}</button>
        </div>
    </div>
</form>



