import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-otp',
  templateUrl: './forget-otp.component.html',
  styleUrls: ['./forget-otp.component.css']
})
export class ForgetOtpComponent implements OnInit {
  usertype:any;
  organizationEmail:any;
  otpForm: FormGroup;
  //create_sucess_msg:any;
  update_data_error_msg:any;
  loader:any= false;
  //success:any=false;
  error:any=false;
  backdrop:any= false;
  error_msg:any;
  show_error:any=false;
  btnDisable: boolean=false;
  
  constructor(private router:Router ,
              private otpservice:GlobalserviceService,
              private activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
   
    this.organizationEmail = this.activatedRoute.snapshot.params.organizationEmail;
    console.log(this.organizationEmail);

    this.otpForm = new FormGroup(
      {
          "otp" : new FormControl(this.otpForm,[Validators.required,Validators.pattern(/^[0-9]*$/)]),
        //  "resendotp" : new FormControl(this.emailForm,[Validators.required,Validators.pattern(/^[0-9]*$/)]),
      }
    );
  }
  resendOTP(){
    var emaildata =
    { 
      organizationEmail : this.organizationEmail,
     
    };
    console.log(emaildata);
    this.backdrop=true;
    this.loader=true;
    this.otpservice
    .forgetpassword(emaildata)
    .subscribe( (data:any) =>
    {
      this.backdrop = false;
      this.loader = false;
      //this.success = true;
      Swal.fire(
        {
          text: data.description,
          icon: 'success'
        }
      );
     // this.create_sucess_msg = data.description;
        // setTimeout(() => {
        //   this.success = false;
        // }, 3000)
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        this.update_data_error_msg = err.error.message;
        console.log(this.update_data_error_msg);
      }
    );
    
  } 
 
  submitData()
  {
    if(this.otpForm.invalid)
    {
      return;
    }
    this.btnDisable = true;
    var otpdata =
    { 
      organizationEmail : this.organizationEmail,
      token : this.otpForm.value.otp,
     
    };
    console.log(otpdata);
    this.backdrop=true;
    this.loader=true;
    this.otpservice
    .otp(otpdata)
    .subscribe( (data:any) =>
    {
      this.backdrop = false;
      this.loader = false;
      //this.success = true;
      // Swal.fire(
      //   {
      //     text: data.description,
      //     icon: 'success'
      //   }
      // );
      //this.create_sucess_msg = data.description;
      this.show_error = false;

      this.otpservice
      .getemployyebyEmail(this.organizationEmail)
      .subscribe( (data:any) =>
      {
        console.log(data);
        setTimeout(() => {
          this.router.navigate([`/new-password/${data.employeeId}`]); 
        }, 3000)

      })
      },
      (err) =>
      {
        this.backdrop = false;
        this.loader = false;
        this.error = true;
        Swal.fire({
          text: err.error.description,
          icon: 'error'
        }
        )
        //this.error_msg = err.error.description;
        this.show_error = true;
        this.btnDisable = false;
      }
    )
  } 
  get otp()
  {
    return this.otpForm.get('otp');  
  }


}