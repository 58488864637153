<div style="height: 100%;overflow: hidden;">
    <html>
        <body class="container-fluid login-background">   
            <div class="card-body login-card-body card-background">
                <form [formGroup]="otpForm" (ngSubmit)="submitData()">                  
                    <div class="col-md-12 content">
                        <h1 class="login-title">OTP</h1>
                    </div> 
                    <!-- <div class="col-md-12" id="loginform">
                        <input type="text" class="form-control email-input"  formControlName="otp" maxlength="6" placeholder="Enter OTP">
                        <div *ngIf="(otp.invalid && otp.touched) || otp.dirty">
                            <small *ngIf="otp.errors?.required" class="text-danger">Please enter OTP</small>
                            <small *ngIf="otp.errors?.pattern" class="text-danger">Please enter valid OTP</small>   
                        </div> 
                    </div>    -->
                    <div class="">
                        <mat-form-field style="width: 100%" appearance="outline">
                            <mat-label style="font-size: 15px;">OTP</mat-label>
                            <mat-icon style="font-size: 22px;" matPrefix>account_box</mat-icon>
                            <input matInput type="text" formControlName="otp" maxlength="6" placeholder="Enter OTP">
                        </mat-form-field>
                        <div class="valid-msg" *ngIf="(otp.invalid && otp.touched) || otp.dirty">
                            <small *ngIf="otp.errors?.required" class="text-danger">Please enter OTP</small>
                            <small *ngIf="otp.errors?.pattern" class="text-danger">Please enter valid OTP</small>   
                        </div> 
                    </div> 
                    <div class="">
                    <span><a (click)="resendOTP()" class="Resend" >Resend OTP</a></span>
                    </div>
                             
                    <!-- <div class="col-md-12 text-center login-button">
                        <Button type="submit" [disabled]="otpForm.invalid || btnDisable" class="btn btn-block login-button-text">Submit</Button>
                    </div> -->
                    <div class="form-group">
                        <button [disabled]="otpForm.invalid || btnDisable"  type="submit" class="btn btn-primary">Submit</button>
                    </div>
                    <div>
                        <p *ngIf="show_error" class="text-danger text-center mt-3 mb-0">{{error_msg}}</p>
                    </div>
                </form>  
            </div>
        </body>
    </html>
    <div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
    <div *ngIf="loader" class="align-items-center text-center" style="z-index: 99;position: relative;bottom:45vh">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
    </div>  
    <!-- <div class="text-center" *ngIf="success"> 
        <div class="alert alert-success" role="alert">
            <strong>Success!</strong><br>{{create_sucess_msg}}
        </div>
    </div> -->
    </div>
    
