import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { url } from '../providers/properties'
import { AuthService } from '../Authenticaton/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  theme: boolean;
  testpic:any;
  image:any
  User_Name: string;
  Profile_Image: any;
  notify: any = 0;
  withdrawNotify:any =0;
  wfhNotify:any =0;
  btNotify:any =0;
  permissionNotify:any = 0;
  showNotify: boolean = true;
  showWithdrawNotify:boolean = true;
  showWfhNotify:boolean = true;
  showBTNotify:boolean = true;
  showPermissionNotify:boolean = true;
  isManager: boolean;
  isHr: boolean;
  myList:any;
  userType:any;
  //
  isShow: boolean
  active: boolean;
  isShowsettings:boolean = false;
  showmanage:boolean = false;
  showadd:boolean = false;
  isreports:boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  User_designation: any;
  isEmployee: boolean=false;

  constructor(private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private gs: GlobalserviceService) { }

  ngOnInit() {

    this.theme = false
    this.image = false
    this.isManager = false
    this.isHr = false
    this.isShow = true;
    this.showmanage = false;
    this.showadd = false;
    this.isreports=false;
    let x = JSON.parse(localStorage.getItem('data'));
    if(x == null)   
    {
      this.router.navigate(['login'])
    }
     this.User_Name = x.employeeName;
     this.User_designation = x.designationResponse.designationName;
    let y = JSON.parse(localStorage.getItem('data'));
    let user_type = y.userType.userType;
    this.userType = y.userType.userType;
    let id = x.employeeId;
    if(user_type == 'HumanResource') {
      this.isHr = true;
    }
    if(user_type == 'Manager') {
      this.isManager = true;
    }
    if(user_type == 'Employee') {
      this.isEmployee = true;
    }
    this.testpic = `${url}employee_profile/${id}`;
    let z = JSON.parse(localStorage.getItem('data'));
    let u = x.userType;
    let mail = x.organizationEmail

    // get employee
    this.gs.getEmpListById(id).subscribe((d:any) => {
      this.myList = d.attachment.fileViewUrl;
    })
    this.gs.getleaveReqPending(mail).subscribe((d: any) => {
      this.notify = d.length
      if (this.notify >= 1) {
        this.showNotify = false;
      }
    })

    this.gs.getemp_withdraw_list(mail).subscribe((d: any) => {
      this.withdrawNotify = d.length
      if (this.withdrawNotify >= 1) {
        this.showWithdrawNotify = false;
      }
    })

    this.gs.getWFHRequest(mail,3).subscribe((d: any) => {
      this.wfhNotify = d.length
      if (this.wfhNotify >= 1) {
        this.showWfhNotify = false;
      }
    })

    this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
      this.btNotify = d.length
      if (this.btNotify >= 1) {
        this.showBTNotify = false;
      }
    })
    this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
      this.permissionNotify = d.length
      if (this.permissionNotify >= 1) {
        this.showPermissionNotify = false;
      }
    })

    this.dateDifference();

    // profile
    this.gs.get_profile(id).subscribe(d => {
      this.image = true
    },
    err => {
      this.image = false
    },
    () => console.log('done')

    )
  }

  toggle() {
    this.isShow = !this.isShow;
  }

  toggleSideNav(nav: MatSidenav) {
    console.log("toggleSideNav");
    const isSmallScreen = this.breakpointObserver.isMatched("(max-width: 959px)");
    if (isSmallScreen) {
      nav.toggle();
    }
  }
  managetoggle(){
    this.showmanage =!this.showmanage;
  } 
  addtoggle(){
    this.showadd =!this.showadd;
  }
  settingstoggle(){
    this.isShowsettings = !this.isShowsettings
  }
  reportstoggle(){
    this.isreports = !this.isreports
  }
  showtimeSettings(){
    this.active = true
  }

  Notify() {
    let x = JSON.parse(localStorage.getItem('data'));
    let mail = x.organizationEmail;
    let y = JSON.parse(localStorage.getItem('data'));
    let user_type = y.userType;
    this.gs.getleaveReqPending(mail).subscribe((d: any) => {
      this.notify = d.length
      if (this.notify  >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getemp_withdraw_list(mail).subscribe((d: any) => {
      this.withdrawNotify = d.length
      if (this.withdrawNotify >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getWFHRequest(mail,3).subscribe((d: any) => {
      this.wfhNotify = d.length
      if (this.wfhNotify  >= 1) {
        // window.location.reload()
      }
    })
    this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
      this.btNotify = d.length
      if (this.btNotify  >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
      this.permissionNotify = d.length
      if (this.permissionNotify < 1) {
        // window.location.reload()
      }
    })
  }



  //
  logout() {
    Swal.fire({
      title: 'Are you sure? Do you want to logout?',
      icon:'success',
      iconHtml: '<img src="assets/img/Vector.png">',
      customClass: {
        icon: 'no-border',
        confirmButton:'swal2-styled.swal2-confirm',
        cancelButton:'swal2-styled.swal2-cancel'
      },
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.value) {
        // Swal.fire(
        //   'Done!'
        // )
        localStorage.clear();
        this.router.navigate(['login'])
      }
      //

    })
  }

  calendar(){
    let x = JSON.parse(localStorage.getItem('data'));
    localStorage.setItem('calendarData',JSON.stringify(x))
  }
   dateDifference() {
    let start = new Date();
    let end = new Date('2020/05/25')
    // Copy date objects so don't modify originals
    var s:any = new Date(+start);
    var e:any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12,0,0,0);
    e.setHours(12,0,0,0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * 5;

    // If not even number of weeks, calc remaining weekend days
    if (totalDays % 7) {
      s.setDate(s.getDate() + wholeWeeks * 7);

      while (s < e) {
        s.setDate(s.getDate() + 1);

        // If day isn't a Sunday or Saturday, add to business days
        if (s.getDay() != 0 && s.getDay() != 6) {
          ++days;
        }
      }
    }
    return days;
  }
  background1() {
      this.theme = !this.theme;

    if(this.theme == false) {

      return `linear-gradient(rgba(0, 123, 255, 0.75), rgba(103, 58, 183, 0.49019607843137253) ),
      url(../../assets/sidenav/mountain.jpg) no-repeat left fixed`

    } else {

      return `linear-gradient( rgba(63, 81, 181, 0.9686274509803922),
      rgba(63, 81, 181, 0.9215686274509803),rgba(63, 80, 181, 0.92) ),
      url(../../assets/img/tree.jpg) no-repeat center fixed`

    }
  }
}
