import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { dialog } from '../leave-req/leave-req.component';

@Component({
  selector: 'app-all-permissison-list',
  templateUrl: './all-permissison-list.component.html',
  styleUrls: ['./all-permissison-list.component.css']
})
export class AllPermissisonListComponent implements OnInit {
  list_count = 0;
  noData=false;
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // displayedColumns =
  //   ['EmployeeNumber','EmployeeName','organizationEmail','MobileNumber','permissionDate', "permissionHours", "workFromHomeStatus"];
  displayedColumns =
    ['employeeNumber','EmployeeName','organizationEmail','permissionDate', "permissionHours", "workFromHomeStatus"];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  dataS: any;
  loader=true;
  stDate:any;
  edDate:any;
  enddate_required:boolean = false;
  startdate_required:boolean = false;
  startdate_validation: boolean = false;
  enddate_errror: boolean = false;
  isSubmitted = false;
  leaveReport:FormGroup;
  min: string;
  show_EMsg:boolean = false;
  max: string;
  submitted: boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  startdate: string;
  enddate: string;
  employeelist: any;
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  constructor(private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    public fb:FormBuilder,
    private datepipe:DatePipe) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"))
    console.log(x);
    let id = x.employeeId;

    this.gs.activeemployeelist().subscribe(
      (d:any)=>{
        console.log(d);
        this.employeelist = d;
      })

    this.leaveReport = this.fb.group({
      startdate:[''],
      enddate:[''],
      employeeId:[''],
    });
    console.log(x.organization.organizationId);
    this.orgId=x.organization.organizationId;
    console.log(this.orgId);
    
    this.gs.getPermissions(this.orgId).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
      {
        this.noData =true;
        console.log("NoData");
      }

      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
       this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
        +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    console.log("Search");
    // if(isNaN(s) && !isNaN(Date.parse(s)))
    // return true;
    // else return false;
    if(!isNaN(Date.parse(filterValue)))
    {
       console.log("date");
       const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
       console.log(searchDate);
       this.dataSource.filter = searchDate .trim().toLowerCase();
       if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    else
    {
      console.log("Number");
      this.dataSource.filter = filterValue .trim().toLowerCase();
      // this.dataSource.filter = filterValue .trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    // let filterValue = (event.target as HTMLInputElement).value;
    // console.log(filterValue);
    // const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    // console.log(searchDate);

    // this.dataSource.filter = searchDate .trim().toLowerCase();
    // this.dataSource.filter = filterValue .trim().toLowerCase();
   
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }
  reason(i) {
    this.dialog.open(PermissionReason, {
      // width:'300px',
      data: i.permissionReason
    });
  }

  // startDate(event)
  // {
  //   // if(event.value==null)
  //   // {
  //   //   console.log(event.value+"Start Date Null");
  //   //   // this.isDisabled = true;
  //   // }
  //   console.log("start Date:"+event.value);
  //   console.log(event.value);
  //   let startDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
  //   console.log(startDate);
  //   this.stDate=startDate;
  //   console.log(this.stDate); 
  //   console.log(this.permissionsfilter.value.startdate);
  //   console.log(this.permissionsfilter.value.enddate);
  //   this.vaildDate();
  // }

  // // endDate
  // endDate(event)
  // {
  //   // this.startdate_validation =false;
  //   // this.enddate_errror =false; 
  //   console.log("end date:"+event.value);
  //   console.log(event.value);
  //   let endDate = this.datepipe.transform(event.value, 'YYYY-MM-dd');
  //   console.log(endDate);
  //   this.edDate=endDate;
  //   console.log(this.edDate);
  //   this.vaildDate();
  // }

  // vaildDate()
  // {
  //    this.enddate_errror =false;
  //   console.log(this.stDate); 
  //   console.log(this.edDate);
  //   if(this.stDate!=null&&this.edDate!=null)
  //   {
  //     console.log("BothStart And EndDate");
  //     // this.enddate_errror =false;
  //   if(this.edDate>=this.stDate)
  //   {
     
  //     this.enddate_errror = false;
  //   }
  //   else
  //   {
  //     console.log("End is LESS then StartDate");
  //     this.enddate_errror = true;
  //   }
  //    }
  //  }

  startDate(event){
    this.startdate_required=false;
    console.log("start Date:"+event.value);
    this.show_EMsg = false;
    this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    if(this.max != null){
      if(this.max < this.min){
        this.show_EMsg = true;
      }
    }
  }
  EndDate(event){
    this.enddate_required=false;
    this.show_EMsg = false;
    console.log("start Date:"+event.value);
    this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  }
  search(){
    this.submitted = true;
    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      return;
    }
    this.startdate = this.datepipe.transform(this.leaveReport.value.startdate,'yyyy-MM-dd');
    console.log(this.startdate);
    this.enddate = this.datepipe.transform(this.leaveReport.value.enddate,'yyyy-MM-dd');
    console.log(this.enddate);
    this.employeeId = this.leaveReport.value.employeeId;
    console.log(this.employeeId);

    if(this.startdate == null && this.enddate == null){
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.all_null=true;
        setTimeout(() => {
          this.all_null = false;
        }, 2000)

        return
      }
    }
    if(this.startdate!=null && this.enddate == null){
      this.enddate_required = true;
      return
    }
    else if(this.enddate !=null && this.startdate == null){
      this.startdate_required = true;
      return
    }
    else{
      this.enddate_required=false;
      this.startdate_required=false;
    }
    this.loader=true;
    if(this.startdate != null && this.enddate != null){
      console.log(this.employeeId=='');
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.gs.filterPermissionList(this.startdate,this.enddate,this.orgId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }
            this.data = d

            this.dataSource = new MatTableDataSource<any>(this.data);
            this.dataSource.filterPredicate = (data, filter) => {
             const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
             +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
             return dataStr.trim().toLowerCase().indexOf(filter) != -1;
           }
           this.dataSource.paginator = this.paginator;
           this.dataSource.sort = this.sort;
           this.list_count = this.data.length



          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
          }
          )
      }
      else{
        this.gs.permissionlistBystartdateenddateempId(this.startdate,this.enddate,this.employeeId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }
            this.data = d

            this.dataSource = new MatTableDataSource<any>(this.data);
            this.dataSource.filterPredicate = (data, filter) => {
             const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
             +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
             return dataStr.trim().toLowerCase().indexOf(filter) != -1;
           }
           this.dataSource.paginator = this.paginator;
           this.dataSource.sort = this.sort;
           this.list_count = this.data.length
          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
          }
          )
      }
    }
    else {
      this.gs.permissionlistByempId(this.employeeId).subscribe(
        (d:any) => {
          console.log(d);
          this.loader=false;
          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d

          this.dataSource = new MatTableDataSource<any>(this.data);
          this.dataSource.filterPredicate = (data, filter) => {
           const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
           +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
           return dataStr.trim().toLowerCase().indexOf(filter) != -1;
         }
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
         this.list_count = this.data.length
        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
        )
    }

  }
  Clear(){
    this.noData = false;
    this.enddate_required = false;
    this.show_EMsg = false;
    this.startdate_required = false;
    this.all_null = false;
    console.log(this.orgId);
    this.gs.getPermissions(this.orgId).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
      {
        this.noData =true;
        console.log("NoData");
      }

      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
       this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
        +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }


isselected(event){
  // console.log(event);
  // this.isDisabled = true;
  // this.submitted=false;
}

}

@Component({
  selector: 'PermissionReason',
  templateUrl: 'PermissionReason.html',
})
export class PermissionReason implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) { }
  ngOnInit(){
  }

  cancel(){
    this.dialog.closeAll()
  }
}
