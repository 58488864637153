import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginComponent } from './Authenticaton/login/login.component';
import { GuardGuard } from './Authenticaton/guard.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmployeeListComponent } from './components/employee/employee-list/employee-list.component';
import { AddLeaveComponent } from './components/add-leave/add-leave.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TypeAddComponent } from './components/leave-type/type-add/type-add.component';
import { TypeListComponent } from './components/leave-type/type-list/type-list.component';
import { EmployeeCreateComponent } from './components/employee/employee-create/employee-create.component';
import { MyleaveListComponent } from './components/myleave-list/myleave-list.component';
import { LeaveReqComponent } from './components/leave-req/leave-req.component';
import { MyleaveBalComponent } from './components/myleave-bal/myleave-bal.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { EmployeesLeaveListComponent } from './components/employees-leave-list/employees-leave-list.component';
import { WithdrawListComponent } from './components/withdraw-list/withdraw-list.component';
import { AllWithdrawComponent } from './components/all-withdraw/all-withdraw.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { WfhApplyComponent } from './components/work-from-home/wfh-apply/wfh-apply.component';
import { WfhListComponent } from './components/work-from-home/wfh-list/wfh-list.component';
import { BusinessApplyComponent } from './components/business-travel/business-apply/business-apply.component';
import { BusinesEmpRequestComponent } from './components/busines-emp-request/busines-emp-request.component';
import { WfhEmpRequestComponent } from './components/wfh-emp-request/wfh-emp-request.component';
import { BusinessTravelListComponent } from './components/business-travel/business-travel-list/business-travel-list.component';
import { LeaveTypeListComponent } from './components/leave-type-list/leave-type-list.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ResourceTaskListComponent } from './project/resource-task-list/resource-task-list.component';
import { MytaskComponent } from './mytask/mytask.component';
import { PermissionComponent } from './components/permission/permission.component';
import { ManagePermissionComponent } from './components/manage-permission/manage-permission.component';
import { PermissionListComponent } from './components/permission-list/permission-list.component';
import { LeaveAdjustmentComponent } from './components/leave-adjustment/leave-adjustment.component';
import { LeaveAdjustmentListComponent } from './components/leave-adjustment-list/leave-adjustment-list.component';
import { AllPermissisonListComponent } from './components/all-permissison-list/all-permissison-list.component';
import { BranchComponent } from './components/branch/branch.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { Dashboard1Component } from './components/dashboard1/dashboard1.component';
import { BranchlistComponent } from './components/branchlist/branchlist.component';
import { EmployeeInOutComponent } from './employee-in-out/employee-in-out.component';
import { EmployeeAttendenceComponent } from './employee-attendence/employee-attendence.component';
import { EmployeeInOutListComponent } from './employee-in-out-list/employee-in-out-list.component';
import { EmployeeTimingsImportComponent } from './employee-timings-import/employee-timings-import.component';
import { EmployeeTimeSettingsComponent } from './employee-time-settings/employee-time-settings.component';
import { EmployeeTimeSettingsListComponent } from './employee-time-settings-list/employee-time-settings-list.component';
import { WorkfromhomeAllListComponent } from './workfromhome-all-list/workfromhome-all-list.component';
import { BusinesstravelAllListComponent } from './businesstravel-all-list/businesstravel-all-list.component';
import { DesignationComponent } from './designation/designation.component';
import { AddDesignationComponent } from './add-designation/add-designation.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgetOtpComponent } from './forget-otp/forget-otp.component';
import { NewPasswordComponent } from './new-password/new-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'prefix' },
  { path:'forget-password', component:ForgetPasswordComponent},
  { path:'otp/:organizationEmail',component:ForgetOtpComponent},
  { path:'new-password/:employeeId', component:NewPasswordComponent},
  {
    path: 'Home', component: MainLayoutComponent, children: [
      {path: '', redirectTo: 'calendar', pathMatch: 'prefix'},
      {path: 'apply', component: AddLeaveComponent},
      {path: 'select', component: MultiselectComponent},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'dashboard1', component: Dashboard1Component},
      {path: 'employee', component: EmployeeListComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'type-add', component:TypeAddComponent},
      {path: 'type-edit/:id', component:TypeAddComponent},
      {path: 'type-list', component: TypeListComponent},
      {path: 'leave-type-list', component: LeaveTypeListComponent},
      {path: 'leave-type-edit/:id', component: LeaveTypeListComponent},
      {path: 'employee', component: EmployeeListComponent},
      {path: 'employee-create', component: EmployeeCreateComponent},
      {path: 'employee-edit/:id', component: EmployeeCreateComponent},
      {path: 'myleave', component: MyleaveListComponent},
      {path: 'request', component: LeaveReqComponent},
      {path: 'leavebalance', component: MyleaveBalComponent},
      {path: 'settings', component: AccountSettingsComponent},
      {path: 'empLeaveList', component: EmployeesLeaveListComponent},
      {path: 'withdraw', component: WithdrawListComponent},
      {path: 'withdrawList', component: AllWithdrawComponent},
      {path: 'workFromHome-apply', component: WfhApplyComponent},
      {path: 'workFromHome-list', component: WfhListComponent},
      {path: 'business-apply', component: BusinessApplyComponent},
      {path: 'business-request', component: BusinesEmpRequestComponent},
      {path: 'wfh-request', component: WfhEmpRequestComponent},
      {path: 'myBusiness-List', component: BusinessTravelListComponent},
      {path: 'permission',component:PermissionComponent},
      {path: 'manage-permission',component:ManagePermissionComponent},
      {path: 'permission-list',component:PermissionListComponent},
      {path: 'leave-adjustment',component:LeaveAdjustmentComponent},
      {path: 'adjustment-list',component:LeaveAdjustmentListComponent},
      {path: 'permission-list-all',component:AllPermissisonListComponent},
      {path: 'workfromhome-list-all',component:WorkfromhomeAllListComponent},
      {path: 'businessTravel-list-all',component:BusinesstravelAllListComponent},
      {path: 'branch',component:BranchComponent},
      {path: 'branch/:frombranch',component:BranchComponent},
      {path : 'branch-list' , component:BranchlistComponent},
      {path: 'branch-edit/:id', component: BranchComponent},
      {path: 'calendar',component:CalendarComponent},
      {path: 'empInOut', component:EmployeeInOutComponent},
      {path: 'empAttendenceList', component:EmployeeAttendenceComponent},
      {path: 'empInOutList', component:EmployeeInOutListComponent},
      {path: 'empInOut/:headerid', component:EmployeeInOutComponent},
      {path: 'EmpimportTimings', component:EmployeeTimingsImportComponent},
      {path: 'Emptimesettings', component:EmployeeTimeSettingsComponent},
      {path: 'Emptimesettings-list', component:EmployeeTimeSettingsListComponent},
      {path: 'designation', component:DesignationComponent},
      {path: 'add-designation', component:AddDesignationComponent},
      {path: 'my-calendar', loadChildren: () => import('./my-calendar/my-calendar.module').then(m => m.MyCalendarModule) },
    ]
  },
  { path: 'login', component: LoginComponent},
  { path: 'project-list', component: ProjectListComponent},
  { path: 'resource-task-list', component: ResourceTaskListComponent},
  { path: 'task', component: MytaskComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
