import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotMustMatch } from '../../account-settings/notmatch';
import { MustMatch } from '../../account-settings/mustmatch';
import { UserType } from 'src/app/model/usertype.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-employee-create',
  templateUrl: './employee-create.component.html',
  styleUrls: ['./employee-create.component.css']
})
export class EmployeeCreateComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  label: string = 'create employee';
  button_label: string = 'save';
  myform: FormGroup;
  File: any;
  submitted = false;
  testfile: any;
  employeeList: any[] = [];
  isLoading: Boolean;
  id: any;
  orgId:any;
  hide: boolean;
  userTypeList: UserType[] = [];
  employeeListData: any
  reportingToEmail: any;
  reportingToSecondEmail: any;
  myuserType;
  sApprover: boolean;
  sApprover1: boolean;
  gender: any;
  bloodGroupValue;
  designationValue;
  genderValue;
  organization:any = [];
  branchList:any = [];
  idOrg=[];
  //constructor
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private gs: GlobalserviceService
  ) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.hide = true
    this.getRouterId()
    this.formValues()
    this.getUserType()
    this.getEmployeeList();
    this.gs.getListTypeById(3).subscribe(
      (data) => {
        this.bloodGroupValue = data;
      }
    )

    this.gs.getListTypeById(4).subscribe(
      (data) => {
        this.designationValue = data;
      }
    )

    this.gs.getListTypeById(2).subscribe(
      (data) => {
        this.genderValue = data;
      }
    )

    this.gs.getOrganization().subscribe(
      (data:any) =>{
        console.log(data);
        this.organization = data;
        console.log("organization List");
        console.log(this.organization);
      }
    )
    // this.gs.getBranch().subscribe(
    //   (data:any) =>{
    //     console.log(data);
    //     data.forEach(element => {
    //       console.log(element);
    //       if(element.branchstatus==1)
    //       {
    //         // this.branchList = element;
    //         this.branchList.push(element);
    //       }
    //       // console.log(this.branchList)
    //     });
    //     console.log(this.branchList);
    //   }
    // )
     console.log(this.id);
  //    if(this.id)
  //    {
  //      console.log("Update Branch");
  //      this.gs.getBranchActive().subscribe(
  //       (data:any) =>{
  //         console.log(data);
  //         // data.forEach(element => {
  //         //   console.log(element);
  //         //   if(element.branchstatus==1)
  //         //   {
  //         //     // this.branchList = element;
  //         //     this.branchList.push(element);
  //         //   }
  //         //   // console.log(this.branchList)
  //         // });
  //         this.branchList = []
  //         console.log(this.branchList);
  //         this.branchList=data;
  //         console.log(this.branchList);
  //       }
  //     )
  //    }
  //  else
  //  {
    // console.log("Create Branch");
    // this.myform.patchValue(
    //   {
    //     organization: student.id
    //   }
    // )
    // this.gs.getBranchActive(this.orgId).subscribe(
    //   (data:any) =>{
    //     console.log(data);
    //     this.branchList=data;
    //     console.log(this.branchList);
    //   }
    // )
  //  }
  }
  // formValues
  private formValues() {
    if (this.id) {
      this.myform = this.fb.group({
        employeeName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        organizationEmail: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
        personalEmail: [''],
        password: [''],
        mobileNumber: ['', [Validators.required, Validators.minLength(10)]],
        emergencyContactNumber: ['', [Validators.required, Validators.minLength(10)]],
        dateOfBirth: ['', [Validators.required]],
        gender: ['', [Validators.required]],
        bloodGroup: ['', Validators.required],
        designation: ['', Validators.required],
        employeeJoinedDate: ['', Validators.required],
        userType: ['', [Validators.required]],
        address: ['', [Validators.required]],
        reportingTo: ['', [Validators.required]],
        noOfWorkingDays: ['', [Validators.required]],
        employeeNumber: ['', [Validators.required]],
        reportingToSecond: [''],
        reportingToSecond1: ['', [Validators.required]],
        attachment: new FormControl(),
        organization: ['', [Validators.required]],
        branch:['',Validators.required]
      },
        {
          validators: NotMustMatch('organizationEmail', 'personalEmail')
        }
      );
    } else {
      this.myform = this.fb.group({
        employeeName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
        organizationEmail: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
        personalEmail: [''],
        password: ['', [Validators.required, Validators.minLength(6)]],
        mobileNumber: ['', [Validators.required, Validators.minLength(10)]],
        emergencyContactNumber: ['', [Validators.required, Validators.minLength(10)]],
        dateOfBirth: ['', [Validators.required]],
        gender: ['', [Validators.required]],
        bloodGroup: ['', Validators.required],
        designation: ['', Validators.required],
        employeeJoinedDate: ['', Validators.required],
        userType: ['', [Validators.required]],
        address: ['', [Validators.required]],
        reportingTo: ['', [Validators.required]],
        noOfWorkingDays: ['', [Validators.required]],
        reportingToSecond: [''],
        reportingToSecond1: ['', [Validators.required]],
        employeeNumber: ['', [Validators.required]],
        attachment: new FormControl(),
        organization: ['', [Validators.required]],
        branch:['',Validators.required]
      }, {
        validators: NotMustMatch('mobileNumber', 'emergencyContactNumber')
      }
      );
    }

  }
  optionClick(organizationId,$event)
  {
    console.log(organizationId);
    this.idOrg=organizationId;
    console.log(this.idOrg);
    this.gs.getBranchActive(organizationId).subscribe(
      (data:any) =>{
        console.log(data);
        this.branchList=data;
        console.log(this.branchList);
      }
    )
    console.log("Branch List");
    this.gs.getBranchActive(this.orgId).subscribe(
      (data:any) =>{
        console.log(data);
        this.branchList=data;
        console.log(this.branchList);
      }
    )
      
    // console.log(event);
  }

  private getEmployeeList(): void {
    this.gs.getEmpList().subscribe(
      (d: any) => {
        this.employeeList = d;
      }
    )
  }

  form(e) {
    if (e != 'Employee') {
      this.sApprover = true;
      this.sApprover1 = false;
    }
    if (e == 'Employee') {
      this.sApprover1 = true;
      this.sApprover = false;
    }
  }

  private getRouterId(): void {
    this.route.paramMap.subscribe(d => {
      this.id = d.get('id');
      if (this.id) {
        this.hide = true;
        this.label = 'update employee'
        this.button_label = 'update'
        this.gs.getEmpListById(this.id).subscribe(
          (d: any) => {
            console.log(d);
            console.log(d.organization.organizationId);
            console.log(d.branch.branchId);
            // if(d.branch.branchId!=0)
            // {
            //   this.branch=d.branch.branchId;
            //   console.log();
            // }
            
            this.gs.getBranchActive(d.organization.organizationId).subscribe(
              (data:any) =>{
                console.log(data);
                this.branchList=data;
                console.log(this.branchList);
              }
            )
            
            this.employeeListData = d;
            this.hide = false;

            console.log(d.gender)

            if (d.gender == 10) {
              this.gender = "Female"
            }
            if (d.gender == 11) {
              this.gender = "Male"
            }

            this.gs.getEmpListById(d.reportingTo).subscribe(
              (e: any) => {
                this.reportingToEmail = e.organizationEmail;
              }
            )
            this.gs.getEmpListById(d.reportingToSecond).subscribe(
              (x: any) => {
                this.reportingToSecondEmail = x.organizationEmail;
              }
            )
            if (d.userType.userType != 'Employee') {
              this.sApprover = true;
              this.sApprover1 = false;
            }
            else {
              this.sApprover1 = true;
              this.sApprover = false;
            }
            this.patchValue(d)
          }
        )
      }
    });
  }

  private getUserType(): void {
    this.gs.get_usertype().subscribe((u: UserType[]) => {
      this.userTypeList = u;
    })
  }

  get f() {
    return this.myform.controls;
  }

  //patch values
  private patchValue(d) {
    this.gs.getBranchActive(this.idOrg).subscribe(
      (a:any)=>{
        a.map(
          e=>{
            if(e.organization.organizationId == d.organization.organizationId){
              this.branchList.push(e)
            }
          }
        )
      }
    )
    if(d.branch.branchId){
      console.log("Update Employee");
      this.myform.patchValue({
       
        employeeId: d.employeeId,
        employeeName: d.employeeName,
        gender: d.gender,
        employeeNumber: d.employeeNumber,
        dateOfBirth: new Date(d.dateOfBirth),
        bloodGroup: d.bloodGroup,
        organizationEmail: d.organizationEmail,
        personalEmail: "",
        mobileNumber: d.mobileNumber,
        emergencyContactNumber: d.emergencyContactNumber,
        organization: d.organization.organizationId,
        branch:d.branch.branchId,
        address: d.address,
        designation: d.designation,
        userType: d.userType.userTypeId,
        reportingTo: d.reportingTo,
        employeeJoinedDate: new Date(d.employeeJoinedDate),
        noOfWorkingDays: Number(d.noOfWorkingDays),
        reportingToSecond: d.reportingToSecond,
        reportingToSecond1: d.reportingToSecond
      })
    }
    else{
      console.log("Craete Employee");
      this.myform.patchValue({
        employeeId: d.employeeId,
        employeeName: d.employeeName,
        gender: d.gender,
        employeeNumber: d.employeeNumber,
        dateOfBirth: new Date(d.dateOfBirth),
        bloodGroup: d.bloodGroup,
        organizationEmail: d.organizationEmail,
        personalEmail: "",
        mobileNumber: d.mobileNumber,
        emergencyContactNumber: d.emergencyContactNumber,
        organization: d.organization.organizationId,
        branch:'',
        address: d.address,
        designation: d.designation,
        userType: d.userType.userTypeId,
        reportingTo: d.reportingTo,
        employeeJoinedDate: new Date(d.employeeJoinedDate),
        noOfWorkingDays: Number(d.noOfWorkingDays),
        reportingToSecond: d.reportingToSecond,
        reportingToSecond1: d.reportingToSecond
      })
    }

    let x = new Number(d.noOfWorkingDays);
    this.f.noOfWorkingDays.setValue(x.toString());
  }

  // keys
  omit_special_char(event) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }
  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  org(d){
    this.branchList = []
    this.gs.getBranchActive(this.orgId).subscribe(
      (a:any)=>{
        a.map(
          e=>{
            if(e.organization.organizationId == d){
              this.branchList.push(e)
            }
          }
        )
      }
    )
  }
  // file
  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (fileList) {
        for (let i = 0; i < fileList.length; i++) {
          const file: any = {
            fileName: '',
            fileType: '',
            fileSize: '',
            fileByte: '',
          };
          file.fileName = fileList[i].name;
          file.fileType = fileList[i].type;
          file.fileSize = fileList[i].size;
          const reader = new FileReader();

          reader.onload = () => {
            let x: any = reader.result;
            let y: any = x.split(',')
            file.fileByte = y[1];
            this.testfile = file;
          }
          reader.readAsDataURL(fileList[i])
        }
      }
    }
  }
  arr: any;
  test: any;
  joiningDate: any
  samapleData: Array<any>;
  samapleData1: Array<any>;
  stringArr: Array<any> = [];
  json1: any;
  onSubmit() {
    this.submitted = true;
    let x = this.myform.value
    if (this.sApprover) {
      x['reportingToSecond'] = x.reportingToSecond;
      this.f.reportingToSecond1.setValue('secondApprover')
    }

    if (this.sApprover1) {
      x['reportingToSecond'] = x.reportingToSecond1;
      if (this.f.reportingToSecond1.value == 'secondApprover') {
        this.myform.controls['reportingToSecond1'].setErrors({ 'required': true })
        return
      }
    }
    if (this.myform.invalid) {
      return
    }
    x['employeeId'] = Number(this.id)
    x['attachment'] = this.testfile
    x['userType'] = {
      userTypeId: this.f.userType.value
    }
    x['reportingTo'] = x.reportingTo;
    x['branch'] = {
      branchId:Number(x.branch)
    }
    x['status'] = 1;
    this.arr = this.myform.value
    this.isLoading = true;
    this.onSubmitConditions(x);
  }
  private deleteLeave(x) {
    const balance = new Array()
    let bal = x.leaveBalance
    bal.forEach(a => {
      let x
      if (this.id) {
        x = {
          leaveBalanceId: a.leaveBalanceId,
          leaveType: {
            leaveTypeId: a.leaveTypeId
          },
          noOfDays: parseInt(a.noOfDays)
        }
        balance.push(x)
      }
      else {
        x = {
          leaveType: {
            leaveTypeId: a.leaveTypeId
          },
          noOfDays: parseInt(a.noOfDays)
        }
        balance.push(x)
      }
    })
    return balance

  }
  private onSubmitConditions(x) {
    x['organization'] = {
      organizationId: this.f.organization.value,
    }
    if (this.id) {
      // x['organization'] = {
      //   organizationId: this.employeeListData.organization.organizationId,
      //   organizationName: this.f.organization.value
      // }
      this.arr['employeeId'] = Number(this.id);
      delete this.arr.password;
      const Form = JSON.stringify(this.arr);
      this.updateData(x)
    }
    else {
      // x['organization'] = {
      //   organizationName: this.f.organization.value,
      //   attachment: this.testfile
      // }
      const Form = JSON.stringify(this.arr);
      const formData: FormData = new FormData();
      this.saveData(x)
    }
  }
  // UpdateData
  private updateData(formData) {
    this.gs.UpdateEmployee(this.id, formData).subscribe(
      d => {

        Swal.fire({
          text: 'Updated Successfully',
          icon: 'success'
        })
        this.isLoading = false;
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        this.isLoading = false
      },
      () => {
        this.isLoading = false;
        this.router.navigate(['/Home/employee'])

      }
    )
  }
  // Save Data
  private saveData(formData): void {
    this.gs.CreateEmployee(formData).subscribe(
      d => {
        Swal.fire({
          text: 'Created Successfully',
          icon: 'success'
        })
        this.isLoading = false;
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        this.isLoading = false
      },
      () => {
        this.isLoading = false
        this.router.navigate(['../../leave-type-list'])
      }
    )
  }
}
