import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { LocationComponent } from '../business-travel/business-travel-list/business-travel-list.component';
import Swal from 'sweetalert2';
import { DenyreasonComponent } from '../denyreason/denyreason.component';

@Component({
  selector: 'app-busines-emp-request',
  templateUrl: './busines-emp-request.component.html',
  styleUrls: ['./busines-emp-request.component.css']
})
export class BusinesEmpRequestComponent implements OnInit {
  isLoading: Boolean;
  list_count = 0
  data: any[] = []
  noData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'organizationEmail', 'startDate', 'endDate', "noOfDays",'reason', "status", 'star',];
  dataSource: any;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"))
    let id = x.employeeId;
    const mail = x.organizationEmail

    this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
      console.log(d);
      // console.log(d.length);
      // console.log(d.statusCode);
      this.loader=false;
      if(d.statusCode==204)
      { 
        this.noData=true;
        console.log("noData");
      }
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.empl.employeeName + data.empl.organizationEmail
        +data.startDate + data.endDate + data.noOfDays + data.businessTravelStatusName;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("Business emp request error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    this.dialog.open(Reason, {
      data: i.reason
    });
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }    
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }
  // show Loacation
  showLocation(i) {
    this.dialog.open(LocationComponent, {
      data: i
    });
  }
  // ++++++++++++++++++++++++++action+++++++++++++++++++++++++++//
  approve(i) {
    if (i.applyTo == 0) {
      i['appplyTo'] = [];
    }
    else {
      i['applyTo'] = [i.empl.organizationEmail];
    }
      i['cc'] = []


    Swal.fire({
      title: 'Are you sure? Do you want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Approve'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true
        i['businessTravelStatus'] = 5;
        this.gs.AcctionBusiness(i.businessTravelId, i).subscribe(d => {
          this.isLoading = false
          Swal.fire(
            'Approved!',
          )
        },
          err => {
            this.isLoading = false
            Swal.fire(
              err.error.message
            )
          }
        );
        setTimeout(() => {
          window.location.reload()
        }, 1000);

      }
      //
    })
  }
  denied(i) {
    if (i.applyTo == 0) {
      i['appplyTo'] = [];
    }
    else {
      i['applyTo'] = [i.empl.organizationEmail];
    }
      i['cc'] = [];


    // leaveStatus
    Swal.fire({
      title: 'Are you sure? Do you want to Deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Deny'
    }).then((result) => {
      if (result.value) {
        //this.isLoading = true
        i['businessTravelStatus'] = 6
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.isLoading = true
              i['businesstraveldenyReason'] = result.denyReason;

              this.gs.AcctionBusiness(i.businessTravelId, i).subscribe(d => {
                this.isLoading = false
                Swal.fire({
                  text:'Denied',
                  icon:'success'
                })
                window.location.reload()
              },
                err => {
                  this.isLoading = false;
                  Swal.fire({
                    text:err.error.message,
                    icon:'error'
                  })
                }
              );
            }
          });
        }
      }
      //
    })
  }

  // ++++++++++++++++++++++++++action+++++++++++++++++++++++++++//** */

}
