<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport data-color="red"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <!--[ngStyle]="{'background': background1()} " -->
    <div class="img">
      <mat-nav-list>
        <span (click)="drawer.toggle()" *ngIf="isHandset$ | async" class="material-icons-outlined drawer-icon last-icon-toggle">chevron_left</span>
        <img src="assets/img/logo3.png" class="brand" alt="">
      </mat-nav-list>
      <mat-nav-list class="title-block">
        <div>
          <h3 class="app-name">Leave<br>Management</h3>
        </div>
      </mat-nav-list>
      <mat-nav-list style="padding-left: 15px;padding-top: 0;" (click)="toggleSideNav(drawer)">
        <a mat-list-item [routerLink]="['calendar']" [routerLinkActive]='["active"]'>
          <span class="material-icons-outlined">dashboard</span> <span class="menu-title">Dashboard</span>
        </a>
        <div class="item-content" *ngIf="isHr == true || isManager">
          <a class="mat-items --profile" mat-list-item (click)="managetoggle()" [class.red]="showmanage == true">
            <!-- <span class="material-icons-outlined sidenav-icons menu-title">settings</span> -->
            <span class="" *ngIf="!showmanage" style="margin-right: 2px;">
              <img src="assets/img/icons/manageemp1.png">
            </span>
            <span class=""  *ngIf="showmanage" style="margin-right: 2px;">
              <img src="assets/img/icons/manageemp.png">
            </span>
            <span class="mat-item" style="width: 80%;">{{"Manage Request"}}</span>
            <span class="material-icons-outlined icon" style="margin-right:0px;">
              {{showmanage ? 'expand_more' : 'chevron_right'}}</span>
          </a>
          <div *ngIf="showmanage == true" class="item-items">
            <ul class="mb-0 p-0" style="list-style:none;">
              <li>
                <a mat-list-item [routerLink]="['request']" [routerLinkActive]='["active"]' (click)="Notify()"
                *ngIf="isHr == true || isManager == true">
                <span class="material-icons-outlined" [matBadge]="notify" [matBadgeHidden]="showNotify">device_hub</span>
                <span class="menu-title">Manage Leave Request</span>
                <!-- <div class="float-right">
                  <mat-icon [matBadge]="notify" matBadgeColor="primary" style="margin-left: 10px;" *ngIf="showNotify">add_alert</mat-icon>
                </div> -->
                <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- Manage Permission -->
                <a mat-list-item [routerLink]="['manage-permission']" [routerLinkActive]='["active"]' (click)="Notify()"
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined" [matBadge]="permissionNotify"
                    [matBadgeHidden]="showPermissionNotify">pending_actions</span> <span class="menu-title">Manage Permission</span>
                  <!-- <div class="float-right">
                      <mat-icon [matBadge]="permissionNotify" matBadgeColor="primary" style="margin-left: 10px;" *ngIf="showPermissionNotify">add_alert</mat-icon>
                    </div> -->
                    <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- business travel request -->
                <a mat-list-item [routerLink]="['business-request']" *ngIf="isHr == true || isManager" (click)="Notify()"
                  [routerLinkActive]='["active"]'>
                  <span class="material-icons-outlined" [matBadge]="btNotify"
                    [matBadgeHidden]="showBTNotify">playlist_add</span> <span class="menu-title">Manage Business Travel</span>
                  <!-- <div class="float-right">
                    <mat-icon [matBadge]="btNotify" matBadgeColor="primary" style="margin-left: 10px;" *ngIf="showBTNotify">add_alert</mat-icon>
                  </div> -->
                  <mat-divider></mat-divider>
                </a>
              </li>

              <li>
                <a mat-list-item [routerLink]="['withdraw']" [routerLinkActive]='["active"]' *ngIf="isHr == true || isManager"
                (click)="Notify()">
                <span class="material-icons-outlined" [matBadge]="withdrawNotify"
                  [matBadgeHidden]="showWithdrawNotify">sync_alt</span> <span class="menu-title">Manage Withdrawal Request</span>
                <!-- <div class="float-right">
                  <mat-icon [matBadge]="withdrawNotify" matBadgeColor="primary" style="margin-left: 10px;" *ngIf="showWithdrawNotify">add_alert</mat-icon>
                </div> -->
                <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- wrok from home emp req -->
                <a mat-list-item [routerLink]="['wfh-request']" [routerLinkActive]='["active"]' (click)="Notify()"
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined" [matBadge]="wfhNotify" [matBadgeHidden]="showWfhNotify">view_list</span>
                  <span class="menu-title">Manage Work From Home</span>
                  <!-- <div class="float-right">
                    <mat-icon [matBadge]="wfhNotify" matBadgeColor="primary" style="margin-left: 10px;" *ngIf="showWfhNotify">add_alert</mat-icon>
                  </div> -->
                  <mat-divider></mat-divider>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="item-content" *ngIf="isHr == true || isManager">
          <a class="mat-items --profile" mat-list-item (click)="addtoggle()" [class.red]="showadd == true">
            <span class="material-icons-outlined sidenav-icons menu-title">add_circle</span>
            <span class="mat-item" style="width: 80%;">{{"Setup"}}</span>
            <span class="material-icons-outlined icon" style="margin-right:0px;">
              {{showadd ? 'expand_more' : 'chevron_right'}}</span>
          </a>
          <div *ngIf="showadd == true" class="item-items">
            <ul class="mb-0 p-0" style="list-style:none;">
              <li>
                <a mat-list-item [routerLink]="['employee']" [routerLinkActive]='["active"]' *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">people </span> <span class="menu-title">Add/Manage Employee</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <a mat-list-item [routerLink]="['branch-list']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">home_work </span> <span class="menu-title">Add/Manage Branch</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <a mat-list-item [routerLink]="['leave-type-list']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">account_balance_wallet </span> <span class="menu-title">Add leave Balance</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- Adjustment list -->
                <a mat-list-item [routerLink]="['adjustment-list']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">equalizer</span> <span class="menu-title">Leave Adjustment</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="item-content" *ngIf="isHr == true || isManager">
          <a class="mat-items profile" mat-list-item (click)="reportstoggle()"  [class.red]="isreports == true">
            <span class="material-icons-outlined sidenav-icons">history</span>
            <span class="mat-item" style="width: 80%;">{{ "Reports"}}</span>
            <span class="material-icons-outlined icon" style="margin-right:0px;">
              {{isreports ? 'expand_more' : 'chevron_right'}}</span>
          </a>
          <div *ngIf="isreports == true" class="item-items">
            <ul class="mb-0 p-0" style="list-style:none;">
              <li>
                  <!-- employee leaves -->
                  <a mat-list-item [routerLink]="['empLeaveList']" [routerLinkActive]='["active"]'
                    *ngIf="isHr == true || isManager">
                    <span class="material-icons-outlined">view_list</span> <span class="menu-title">Leave Detail</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- Permission list -->
                <a mat-list-item [routerLink]="['permission-list-all']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">history</span> <span class="menu-title">Permission Detail</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- Business Travel list -->
                <a mat-list-item [routerLink]="['businessTravel-list-all']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">local_airport</span> <span class="menu-title">Business Travel</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <!-- Work from home list -->
                <a mat-list-item [routerLink]="['workfromhome-list-all']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager">
                  <span class="material-icons-outlined">add_business</span> <span class="menu-title">Work From Home</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- <a mat-list-item [routerLink]="['dashboard']" [routerLinkActive]='["active"]'>
          <span class="material-icons ">today</span> <span>Calendar</span>
        </a> -->
        <!-- <a mat-list-item [routerLink]="['leave-type-list']" [routerLinkActive]='["active"]' *ngIf="userType == 'HumanResource' " >
          <span class="material-icons">receipt_long</span> <span>Leave Type</span>
        </a> -->
        <!-- <a mat-list-item [routerLink]="['leave-adjustment']" [routerLinkActive]='["active"]' *ngIf="userType == 'HumanResource' ">
          <span class="material-icons">equalizer</span> <span>Leave Adjustment</span>
        </a> -->

        <a mat-list-item [routerLink]="['apply']" [routerLinkActive]='["active"]' *ngIf="isEmployee==true">
          <span class="material-icons-outlined">playlist_add</span> <span class="menu-title">Apply Leave</span>
        </a>
        <!-- Permission -->
        <a mat-list-item [routerLink]="['permission']" [routerLinkActive]='["active"]' *ngIf="isEmployee==true">
          <span class="material-icons-outlined">history</span> <span class="menu-title">Apply Permission</span>
        </a>
        <!--work from home -->
        <a mat-list-item [routerLink]="['workFromHome-apply']" [routerLinkActive]='["active"]' *ngIf="isEmployee==true">
          <span class="material-icons-outlined">add_business</span> <span class="menu-title">Apply Work From Home</span>
        </a>
        <!-- mybusinees list -->
        <a mat-list-item [routerLink]="['business-apply']" [routerLinkActive]='["active"]' *ngIf="isEmployee==true">
          <span class="material-icons-outlined ">local_airport</span> <span class="menu-title">Apply Business Travel</span>
        </a>

        <!-- <a mat-list-item [routerLink]="['empInOut']" [routerLinkActive]='["active"]' *ngIf="isHr == true || isManager">
          <span class="material-icons">lock_clock</span> <span>Employee In Out</span>
        </a> -->
        <a mat-list-item [routerLink]="['empInOutList']" [routerLinkActive]='["active"]'
          *ngIf="isHr == true || isManager">
          <!-- <mat-icon>view_list</mat-icon>  -->
          <span class="material-icons-outlined">lock_clock</span>
          <span class="menu-title">Record Timings</span>
        </a>
        <a mat-list-item [routerLink]="['empAttendenceList']" [routerLinkActive]='["active"]'
          *ngIf="isHr == true || isManager">
          <span class="material-icons-outlined">how_to_reg</span> <span class="menu-title">Record Attendence</span>
          <!-- <mat-icon>speaker_notes</mat-icon> -->
        </a>

        <div class="item-content" *ngIf="isHr == true || isManager">
          <a class="mat-items profile" mat-list-item (click)="settingstoggle()"  [class.red]="isShowsettings == true">
            <span class="material-icons-outlined sidenav-icons">settings</span>
            <span class="mat-item" style="width: 80%;">{{ "Settings"}}</span>
            <span class="material-icons-outlined icon" style="margin-right:0px;">
              {{isShowsettings ? 'expand_more' : 'chevron_right'}}</span>
          </a>
          <div *ngIf="isShowsettings == true" class="item-items">
            <ul class="mb-0 p-0" style="list-style:none;">
              <li>
                <a mat-list-item [routerLink]="['type-list']" [routerLinkActive]='["active"]' *ngIf="isHr == true">
                  <span class="material-icons-outlined">local_library</span> <span class="menu-title">Leave Type List</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <a mat-list-item [routerLink]="['Emptimesettings-list']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager" (click)="showtimeSettings()">
                  <span class="material-icons-outlined" style="font-size: 15.6px;">schedule</span>
                  <span class="menu-title">Record Timings Settings</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
              <li>
                <a mat-list-item [routerLink]="['designation']" [routerLinkActive]='["active"]'
                  *ngIf="isHr == true || isManager" (click)="showtimeSettings()">
                  <span class="material-icons-outlined" style="font-size: 15.6px;">work_outline</span>
                  <span class="menu-title">Designation</span>
                  <mat-divider></mat-divider>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </mat-nav-list>
      <mat-nav-list style="padding-left: 15px;" class="logout-menu" (click)="toggleSideNav(drawer)">
        <a mat-list-item (click)="logout()" class="last-container">
          <span class="material-icons-outlined menu-title">logout</span> <span class="menu-title">Logout</span>
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <mat-toolbar-row>
        <span class="example-spacer">
          <span type="button" mat-mini-fab color="warn" aria-label="Toggle sidenav" 
          class="primary" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
            <mat-icon aria-label="Side nav toggle icon" style="vertical-align: middle;">menu</mat-icon>
          </span>
          <!-- <span>
            <h4 style="position: absolute;left: 45px;
                top:12px;font-family: 'Poppins', sans-serif;
                font-style: normal;font-weight: 500;
                font-size: 20px;align-items: center;
                letter-spacing: 0.005em;color: #656B76;"
                >My Dashboard</h4>
          </span> -->
        </span>
        <!-- <mat-toolbar class="profile accordion" id="accordionExample" data-toggle="collapse" data-target="#collapseOne"
          aria-expanded="true" aria-controls="collapseOne" (click)="toggle()">
        </mat-toolbar> -->

        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" mat-mini-fab color="warn"
          [matMenuTriggerFor]="beforeMenu" class="primary">
          <a ngbDropdownToggle type="button" class="btn username" id="dropdownManual" ngbDropdownAnchor
            (focus)="myDrop.open()">
            <ng-container *ngIf="myList == null; else pic">
              <img mat-card-avatar class="mat-card-avatar" src="assets/img/profilepic.jpg" alt="">
            </ng-container>
            <ng-template #pic>
              <img mat-card-avatar [src]="myList" alt="">
            </ng-template>
            <div style="display: grid;margin: 0 5px;" class="isHide">
              <span class="name-of-user">{{ User_Name | titlecase }}</span>
              <span class="job-description">{{User_designation}}</span>
            </div>
          </a>
          <div class="side_setting_profile">
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <a mat-menu-item [routerLink]="['profile']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">account_box</span>My Profile</a>
              <!-- <a mat-menu-item [routerLink]="['leavebalance']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">timelapse</span>My Leave Balance</a> -->
              <a mat-menu-item [routerLink]="['myleave']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">playlist_add</span>My Leave List</a>
              <a mat-menu-item [routerLink]="['permission-list']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">history</span>My Permission</a>
              <a mat-menu-item [routerLink]="['workFromHome-list']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">add_business</span>Work From Home</a>
              <a mat-menu-item [routerLink]="['myBusiness-List']" [routerLinkActive]='["active"]'
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">local_airport</span>Business travel</a>
              <a mat-menu-item target="_blank" [routerLink]="['/my-calendar']" (click)="calendar()"
                style="line-height: 38px;height: 38px;font-size: 12px;text-decoration: none;">
                <span class="material-icons-outlined v">date_range</span>My Calendar</a>
            </mat-menu>
          </div>
        </div>




        <!-- <mat-toolbar class="profile accordion" id="accordionExample" data-toggle="collapse" data-target="#collapseOne"
        aria-expanded="true" aria-controls="collapseOne" (click)="toggle()">
        <ng-container *ngIf="myList == null; else pic">
          <img mat-card-avatar src="assets/img/profilepic.jpg" alt="">
        </ng-container>
        <ng-template #pic>
          <img mat-card-avatar [src]="myList" alt="">
        </ng-template>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="view">
            <a mat-list-item [routerLink]="['profile']" [routerLinkActive]='["active"]'>
              <span class="material-icons ">account_box</span> <span>My Profile</span>
            </a>
            <a mat-list-item [routerLink]="['myleave']" [routerLinkActive]='["active"]'>
              <span class="material-icons ">format_list_bulleted</span> <span>My Leave List</span>
            </a>
            <a mat-list-item [routerLink]="['leavebalance']" [routerLinkActive]='["active"]'>
              <span class="material-icons ">timelapse</span> <span>My Leave Balance</span>
            </a>
            <a mat-list-item [routerLink]="['workFromHome-list']" [routerLinkActive]='["active"]'>
              <span class="material-icons ">add_business</span> <span>Work From Home</span>
            </a>-->
        <!--  Mybusiness list -->
        <!-- <a mat-list-item [routerLink]="['myBusiness-List']" [routerLinkActive]='["active"]'>
              <span class="material-icons">post_add</span> <span>My Business travel List</span>
            </a> -->
        <!-- Permission -->
        <!-- <a mat-list-item [routerLink]="['permission-list']" [routerLinkActive]='["active"]'>
               <span class="material-icons">history</span> <span>Permission</span>
            </a>
          </div>
        </div>
        <hr style="border-top: 1px solid rgba(255, 250, 250, 0.45);"> -->

        <!-- <ng-template #elseTemplate>
          <img mat-card-avatar src="../../../assets/img/profilepic.jpg" alt="">
        </ng-template> -->
        <!-- <div class="" style="width: 100%;margin: -3px 0;">
          <div class="float-right">
            <span class="material-icons toogle" style="line-height: 1.4 !important;color: white; margin: -2px 2px;">
              {{isShow ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}}
            </span>
          </div>
          <span class="name"> {{ User_Name | titlecase }}</span>
        </div>
      </mat-toolbar>  -->


        <!-- <button mat-mini-fab color="warn" [matMenuTriggerFor]="beforeMenu" class="primary">
          <mat-icon class="example-icon">more_vert</mat-icon>
        </button>
        <div class="side_setting">
          <mat-menu #beforeMenu="matMenu" xPosition="before">
            <a mat-menu-item [routerLink]="['profile']"> <span class="material-icons v">account_box</span> My
              Profile</a>
            <a mat-menu-item [routerLink]="['settings']"> <span class="material-icons v">settings</span>Account
              Settings</a>
            <a mat-menu-item target="_blank" [routerLink]="['/my-calendar']" (click)="calendar()"> <span class="material-icons v">date_range</span>
              My Calendar</a>
            <a mat-menu-item (click)="logout()" routerLink>
              <span class="material-icons v">settings_power</span>Logout</a>
          </mat-menu>
        </div> -->
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- settings -->
    <!-- <div class="settings">
      <span class="material-icons" (click)="background1()">{{theme ? 'toggle_off' : 'toggle_on
        '}}</span>
    </div> -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<!-- *ngIf="isHandset$ | async"  -->