<html>

<body class="container-fluid login-background">
    <div class="card-body login-card-body card-background">
        <form [formGroup]="newpasswordForm" (ngSubmit)="submitData()">
            <div class="col-md-12 content">
                <!-- <img src="./assets/logo.png" height="50px" width="130px"> -->
                <h1 class="login-title">New Password</h1>
            </div>
            <div class="">
                <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label style="font-size: 15px;">New Password</mat-label>
                    <mat-icon style="font-size: 22px;" matPrefix>lock</mat-icon>
                    <input matInput type="password" formControlName="newpassword" placeholder="Enter new password"
                    [type]="hide ? 'password' : 'text'">
                    <mat-icon style="font-size: 22px;" type="button" mat-icon-button matSuffix (click)="hide = !hide" 
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <div class="valid-msg" *ngIf="(f.newpassword.invalid && f.newpassword.touched) || f.newpassword.dirty">
                    <small *ngIf="f.newpassword.errors?.required" class="text-danger">Please enter new password</small>
                    <small *ngIf="f.newpassword.errors?.minlength" class="text-danger">Password must be at least 6
                        characters</small>
                </div>
            </div>
            <div class="">
                <mat-form-field style="width: 100%" appearance="outline">
                    <mat-label style="font-size: 15px;">Confirm New Password</mat-label>
                    <mat-icon style="font-size: 22px;" matPrefix>lock</mat-icon>
                    <input matInput type="password" formControlName="confirm_newpassword"
                        placeholder="Confirm new password" [type]="hide ? 'password' : 'text'">
                        <mat-icon style="font-size: 22px;" type="button" mat-icon-button matSuffix (click)="hide = !hide" 
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <div class="valid-msg"
                    *ngIf="(f.confirm_newpassword.invalid && f.confirm_newpassword.touched) ||f.confirm_newpassword.dirty">
                    <small *ngIf="f.confirm_newpassword.errors?.required" class="text-danger">Please enter confirm new
                        password</small>
                    <small *ngIf="f.confirm_newpassword.errors?.mustMatch" class="text-danger">Passwords must
                        match</small>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="newpasswordForm.invalid || btnDisable" type="submit"
                    class="btn btn-primary">Submit</button>
            </div>
            <!-- <div>
                    <p class="text-danger text-center mt-3 mb-0">{{error_msg}}</p>
                </div> -->
        </form>
    </div>
</body>

</html>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div *ngIf="loader" class="align-items-center text-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>
<!-- <div class="text-center" *ngIf="success">
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_error_msg}}
</div> -->