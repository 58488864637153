import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './materialstyle/materialstyle.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { Select2Module } from 'ng-select2-component';
// import { NgSelect2Module } from 'ng-select2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// components
import {LayoutComponent } from './layout/layout.component';
import {MainLayoutComponent } from './main-layout/main-layout.component';
import {DashboardComponent,MyCalendar, AddHoliday, WorkingDays, ExceptionHoliday } from './components/dashboard/dashboard.component';
import {EmployeeListComponent, DialogDataExampleDialog } from './components/employee/employee-list/employee-list.component';
import {AppComponent } from './app.component';
import {LoginComponent } from './Authenticaton/login/login.component';
import {HttpClientModule } from '@angular/common/http';
import {ApplyLeaveComponent } from './components/apply-leave/apply-leave.component';
import {AddLeaveComponent } from './components/add-leave/add-leave.component';
import {ProfileComponent, profile } from './components/profile/profile.component';
import {TypeAddComponent } from './components/leave-type/type-add/type-add.component';
import {TypeListComponent } from './components/leave-type/type-list/type-list.component';
import {EmployeeCreateComponent } from './components/employee/employee-create/employee-create.component';
import {MyleaveListComponent, WithDrawDialog, WithdrawDetails } from './components/myleave-list/myleave-list.component';
import {LeaveReqComponent, dialog } from './components/leave-req/leave-req.component';
import {MyleaveBalComponent } from './components/myleave-bal/myleave-bal.component';
import {AccountSettingsComponent } from './components/account-settings/account-settings.component';
import {EmployeesLeaveListComponent } from './components/employees-leave-list/employees-leave-list.component';

//
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { WithdrawListComponent, Reason, ShowLeave } from './components/withdraw-list/withdraw-list.component';
import { AllWithdrawComponent } from './components/all-withdraw/all-withdraw.component';
import { ListFilterPipe } from './list-filter.pipe';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { ShareLeaveComponent } from './components/share-leave/share-leave.component';
import { WfhApplyComponent } from './components/work-from-home/wfh-apply/wfh-apply.component';
import { BusinessApplyComponent } from './components/business-travel/business-apply/business-apply.component';
import { WfhListComponent } from './components/work-from-home/wfh-list/wfh-list.component';
import { BusinesEmpRequestComponent } from './components/busines-emp-request/busines-emp-request.component';
import { WfhEmpRequestComponent } from './components/wfh-emp-request/wfh-emp-request.component';
import { BusinessTravelListComponent, LocationComponent } from './components/business-travel/business-travel-list/business-travel-list.component';
import { LeaveTypeListComponent } from './components/leave-type-list/leave-type-list.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ResourceTaskListComponent, TaskDescription } from './project/resource-task-list/resource-task-list.component';
import { TaskDialogComponent } from './project/task-dialog/task-dialog.component';
import { MytaskComponent } from './mytask/mytask.component';
import { PermissionComponent } from './components/permission/permission.component';
import { ManagePermissionComponent ,MPermission} from './components/manage-permission/manage-permission.component';
import { PermissionListComponent,Permission } from './components/permission-list/permission-list.component';
import { LeaveAdjustmentComponent } from './components/leave-adjustment/leave-adjustment.component';
import { LeaveAdjustmentListComponent } from './components/leave-adjustment-list/leave-adjustment-list.component';
import { AllPermissisonListComponent, PermissionReason } from './components/all-permissison-list/all-permissison-list.component';
import { DenyreasonComponent } from './components/denyreason/denyreason.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import { NgxPaginationModule } from 'ngx-pagination';
import { BranchComponent } from './components/branch/branch.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { Dashboard1Component } from './components/dashboard1/dashboard1.component';
import { BranchlistComponent } from './components/branchlist/branchlist.component';
import { EmployeeInOutComponent } from './employee-in-out/employee-in-out.component';
import { EmployeeAttendenceComponent } from './employee-attendence/employee-attendence.component';
import { EmployeeInOutListComponent } from './employee-in-out-list/employee-in-out-list.component';
import { EmployeeTimingsImportComponent } from './employee-timings-import/employee-timings-import.component';
import { EmployeepipePipe } from './employeeinout/employeepipe.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { EmployeeInOutListpipePipe } from './employee-in-out-list/employee-in-out-listpipe.pipe';
import { EmpattendencePipe } from './employee-attendence/empattendence.pipe';
import { EmployeeTimeSettingsComponent } from './employee-time-settings/employee-time-settings.component';
import { EmployeeTimeSettingsListComponent } from './employee-time-settings-list/employee-time-settings-list.component';
import { WorkfromhomeAllListComponent } from './workfromhome-all-list/workfromhome-all-list.component';
import { BusinesstravelAllListComponent } from './businesstravel-all-list/businesstravel-all-list.component';
import { DesignationComponent } from './designation/designation.component';
import { AddDesignationComponent } from './add-designation/add-designation.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgetOtpComponent } from './forget-otp/forget-otp.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { PermissionsettingsComponent } from './permissionsettings/permissionsettings.component';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  bootstrapPlugin,
])
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    MainLayoutComponent,
    DashboardComponent,
    MyCalendar,
    AddHoliday,
    WorkingDays,
    ExceptionHoliday,
    EmployeeListComponent,
    LoginComponent,
    ApplyLeaveComponent,
    AddLeaveComponent,
    ProfileComponent,
    TypeAddComponent,
    TypeListComponent,
    EmployeeCreateComponent,
    MyleaveListComponent,
    LeaveReqComponent,
    MyleaveBalComponent,
    AccountSettingsComponent,
    EmployeesLeaveListComponent,
    DialogDataExampleDialog,
    dialog,
    WithDrawDialog,
    WithdrawListComponent,
    Reason,
    Permission,
    PermissionReason,
    MPermission,
    AllWithdrawComponent,
    ShowLeave,
    ListFilterPipe,
    profile,
    MultiselectComponent,
    ShareLeaveComponent,
    WithdrawDetails,
    WfhApplyComponent,
    BusinessApplyComponent,
    WfhListComponent,
    BusinesEmpRequestComponent,
    WfhEmpRequestComponent,
    BusinessTravelListComponent,
    LocationComponent,
    LeaveTypeListComponent,
    ProjectListComponent,
    ResourceTaskListComponent,
    TaskDialogComponent,
    TaskDescription,
    MytaskComponent,
    PermissionComponent,
    ManagePermissionComponent,
    PermissionListComponent,
    LeaveAdjustmentComponent,
    LeaveAdjustmentListComponent,
    AllPermissisonListComponent,
    DenyreasonComponent,
    BranchComponent,
    CalendarComponent,
    Dashboard1Component,
    BranchlistComponent,
    EmployeeInOutComponent,
    EmployeeAttendenceComponent,
    EmployeeInOutListComponent,
    EmployeeTimingsImportComponent,
    EmployeepipePipe,
    EmployeeInOutListpipePipe,
    EmpattendencePipe,
    EmployeeTimeSettingsComponent,
    EmployeeTimeSettingsListComponent,
    WorkfromhomeAllListComponent,
    BusinesstravelAllListComponent,
    AddDesignationComponent,
    DesignationComponent,
    ForgetPasswordComponent,
    ForgetOtpComponent,
    NewPasswordComponent,
    PermissionsettingsComponent
    
  ],
  imports: [
     BrowserModule,
     AppRoutingModule,
     BrowserAnimationsModule,
     FormsModule,
     ReactiveFormsModule,
     NgxPaginationModule,
     MaterialModule, LayoutModule,
     MatToolbarModule, MatButtonModule,
     MatSidenavModule, MatIconModule,
     MatListModule, MatGridListModule,
     MatCardModule, MatMenuModule,
     MatTableModule, MatPaginatorModule,
     MatSortModule,
     HttpClientModule,
     FullCalendarModule,
     Select2Module,
     MatInputModule,
     MatFormFieldModule,
    //  NgSelect2Module
    //  DatePipe,
    NgbModule
  ],
  providers: [{provide : LocationStrategy , useClass: HashLocationStrategy},DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [DenyreasonComponent]
  // DialogDataExampleDialog,DialogDataExampleDialog,LeaveReqComponent
})

export class AppModule {

}
