import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { DenyreasonComponent } from '../denyreason/denyreason.component';

@Component({
  selector: 'app-manage-permission',
  templateUrl: './manage-permission.component.html',
  styleUrls: ['./manage-permission.component.css']
})
export class ManagePermissionComponent implements OnInit {

  isLoading: Boolean;
  list_count = 0
  data: any[] = [];
  noData=false;
  query
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber','employeeName', 'organizationEmail', 'PermissionDate', 'permissionHours','reason','workFromHomeStatus', 'star',];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"));
    let mail = x.organizationEmail;
    this.gs.getEmpListById(x.employeeId).subscribe(
      (data: any) => {
        this.loader=false;
        //  this.gs.getEmpListById(data.reportingTo).subscribe(
        //   (x:any) =>{
          // http://38.242.135.217:6001/leavemanagement/employee_profile/45

          if(data.lenth==0)
          {
            this.noData=true;
            console.log("noData");
          }
          console.log("Employee inside Api to PemissionApi");
        this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
          this.loader=false;
          console.log(d.statusCode);
          if(d.statusCode==204)
          {
            this.noData=true;
            console.log("noData");
          }
          this.data = d
          this.dataSource = new MatTableDataSource<any>(this.data);
          this.dataSource.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.permissionDate
            +data.permissionHours + data.permissionStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.dataSource.paginator = this.paginator;
          this.list_count = this.data.length;
          // })
        },
        (error) =>
        { 
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
          // console.log(error.status);
          // if(error.status==400)
          // {
          //   this.noData=true;
          //    console.log("400");
          // }
        }
        )
      },
      (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.status==404)
      {
         this.noData=true;
         console.log("404");
      }
    }
    )

  }

  test(i) {
    if (i.permissionStatusName == null) {
      return
    }
    if (i.permissionStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.permissionStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.permissionStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.permissionStatusName == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.permissionStatusName == 'Cancelled') {
      return '#e40000'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  Accept(i) {
    Swal.fire({
      title: 'Are you sure? Do you want to Accept',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        i['permissionStatus'] = 5
        this.isLoading = true;
        this.gs.Permission_Status(i.permissionId, i).subscribe(
          d => {
            Swal.fire({
              text: 'Approved',
              icon: 'success'
            });
            this.isLoading = false;
            setTimeout(() => {
              window.location.reload()
            }, 1000);
          },
          err => {
            Swal.fire({
              text: err.error.message
            })
            this.isLoading = false;
          }
        )
      }
      //
    })

  }

  Denied(i) {
    Swal.fire({
      title: 'Are you sure? Do you want to Denied',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes!'
    }).then((result) => {

      if (result.value) {
        i['permissionStatus'] = 6;
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {
              i['permissiondenyReason'] = result.denyReason;
              this.isLoading = true

              this.gs.Permission_Status(i.permissionId, i).subscribe(
                d => {
                  Swal.fire({
                    text: 'Denied',
                    icon: 'success'
                  });
                  this.isLoading = false
                  setTimeout(() => {
                    window.location.reload()
                  }, 1000);
                },
                err => {
                  Swal.fire({
                    text: err.error.message,
                    icon:'error'
                  })
                  this.isLoading = false
                }
              )
            }
          });
        }
      }
      //
    })

  }

  reason(e) {
    const dialogRef = this.dialog.open(MPermission, {
      data: e.permissionReason
    })
  }

}

@Component({
  selector: 'MPermission',
  templateUrl: 'MPermission.html',
})
export class MPermission implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
  }
}


